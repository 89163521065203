@import 'variables';
@import 'mixins';

.uk-lightbox {
    background: rgba(255, 255, 255, 0.8) !important;
}

.product-page {

    .tovar-holder {
        position: relative;
        box-shadow: -1px 3px 5px 0 rgb(166 166 166 / 68%);

        &:before {
            background: url(/images/bg-shadow-01.png) no-repeat;
            bottom: -26px;
            content: "";
            height: 26px;
            left: 50%;
            margin-left: -640px;
            position: absolute;
            width: 1281px;
        }
    }

    .product-wrapper {
        display: grid;
        grid-template-columns: 430px 1fr;
        grid-gap: 0 40px;

        .product-left {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
        }

        .product-right {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
        }

        .product-reviews {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
        }

        .product-img-slider {
            position: relative;
            margin-bottom: 15px;

            .label {
                position: absolute;
                right: 0;
                top: 0;
                display: flex;
                flex-direction: column;

                .new {
                    width: 50px;
                    height: 35px;
                    background: #f32450;
                    color: #fff;
                    font: 18px/35px Arial, Helvetica, sans-serif;
                    padding: 2px 2px;
                    z-index: 1;
                    margin-bottom: 5px;
                    text-align: center;
                }

                .share-item {
                    min-width: 50px;
                    width: auto;
                    height: auto;
                    background: #016ccc;
                    color: #fff;
                    font: 16px/27px 'RobotoCondensedRegular', sans-serif;
                    font-style: italic;
                    padding: 2px 2px;
                    z-index: 1;
                    margin-bottom: 5px;
                    vertical-align: center;
                    text-align: center;
                }

                .sale-item {

                    color: #fff;
                    background: #00a93d;
                    text-align: center;
                    padding: 2px 2px;
                    font: 16px/27px 'RobotoCondensedRegular', sans-serif;
                    margin-bottom: 5px;
                    min-width: 50px;
                    width: auto;
                    height: auto;
                    z-index: 1;
                }
            }

            .navigate {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                width: 35px;
                height: 35px;
                border-radius: 100%;
                background-color: rgba(255, 255, 255, 0.5);
                display: none;
                justify-content: center;
                align-items: center;

            }

            .slider__nav_prev {
                left: 15px;
            }

            .slider__nav_next {
                right: 15px;
            }

            .uk-slider-items {
                .slider-li {
                    width: 100%;
                }
            }
        }

        .product-h1 {
            font-size: 28px;
            font-family: 'Roboto Regular', sans-serif;
            line-height: 30px;
            margin: 18px 0;
            font-weight: 700;
            color: $black;
        }

        .art {
            font: 14px/16px 'RobotoCondensedRegular', sans-serif;
            color: #8d8d8d;
            overflow: hidden;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        .price-box {
            margin-bottom: 25px;

            .price-block {
                margin-right: 30px;
                display: inline-block;
                vertical-align: top;
                line-height: 20px;
                margin-top: 15px;
            }

            .share-old-price {
                text-decoration: line-through;
                display: inline;
                color: #737373;
                text-decoration-color: #f32450;
                margin-right: 15px;
                font-size: 22px;
            }


            .price-opt {
                position: relative;
                margin-bottom: 5px;

                .share-old-price {
                    display: inline;
                    font-size: 22px;
                    text-decoration: line-through;
                    text-decoration-color: #f32450;
                    margin-right: 15px;
                    margin-left: 0;
                }

                &:before {
                    content: 'опт';
                    font-family: 'RobotoCondensedRegular', sans-serif;
                    font-size: 12px;
                    color: #f32450;
                    position: absolute;
                    top: -17px;
                    left: 0;
                }

                span {
                    color: #f32450;
                    font-size: 28px;
                }
            }

            .price-retail {
                position: relative;

                &:before {
                    content: 'розница';
                    font-family: 'RobotoCondensedRegular', sans-serif;
                    font-size: 12px;
                    position: absolute;
                    bottom: -13px;
                    left: 0;
                }

                span {
                    font-size: 28px;
                }
            }

            .buy-buttons {
                display: inline-block;
                vertical-align: top;
                line-height: 20px;
                margin-top: 15px;

                a {
                    margin: 0 5px;
                }

                .buy-btn {
                    @include buy-btn;
                }

                .buy-one-click-btn {
                    @include buy-one-click-btn;
                }

                .not-available {
                    display: block;
                    line-height: 36px;
                    font-size: 36px;
                    color: $red;

                    a {
                        margin: 0 0;
                        display: block;
                        font-size: 20px;
                        line-height: 20px;
                        color: $red;
                    }
                }

                .last-price-text {
                    color: #767676;
                    font-size: 22px;
                    margin: 22px 0;
                }

                .last-price {
                    display: inline-block;
                    padding: 7px 10px;
                    font-size: 24px;
                    font-weight: bold;
                    color: #767676;
                    background: #eee;
                }
            }

        }

        .size-box {
            overflow: hidden;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #e0daf4;
            clear: both;

            .size-list {
                margin: 0;
                padding: 0;
                list-style: none;
                float: left;

                li {
                    margin-bottom: 5px;
                    overflow: hidden;

                    .box {
                        color: #178ccd;
                        border: 1px solid #178ccd;
                        width: 100px;
                        height: 35px;
                        text-align: center;
                        font: 18px/35px 'RobotoRegular', sans-serif;
                        vertical-align: middle;
                        float: left;
                        margin-right: 10px;
                    }

                    .counter-01 {
                        overflow: hidden;
                        float: left;
                        margin-top: 5px;

                        span {
                            display: inline-block;
                            width: 25px;
                            height: 25px;
                            background: #919191;
                            text-align: center;
                            vertical-align: middle;
                            color: #fff;
                            font: 18px/25px Arial, Helvetica, sans-serif;
                            cursor: pointer;
                            -moz-user-select: none;
                            user-select: none;

                        }

                        .cnt {
                            width: 57px;
                            height: 24px;
                            border: 1px solid #9dd1f1;
                            background: #fff;
                            display: inline-block;
                            text-align: center;
                            color: #919191;
                            font: 13px/35px 'RobotoRegular', sans-serif;
                            line-height: 13px;
                        }

                    }

                }
            }

            .info-box {
                width: 225px;
                float: right;
                overflow: hidden;

                .inf {
                    float: right;
                    clear: both;
                    margin-bottom: 15px;
                    color: #787576;
                    font: 13px/16px 'RobotoRegular', sans-serif;
                    position: relative;
                    width: 100%;
                    text-align: right;
                    padding-left: 20px;
                    box-sizing: border-box;
                }

                .btn-03 {
                    color: $blue !important;
                    font: 16px/18px 'RobotoRegular', sans-serif;
                    text-decoration: underline !important;
                    transition: all .3s ease;
                    display: inline-block;
                }
            }

            .opt-info {
                background: url(/images/box-icon.png) no-repeat;
                width: 240px;
                color: #f32450;
                font-family: 'RobotoBold', sans-serif;
                font-size: 18px;
                padding-left: 50px;
                margin: auto;
                line-height: 22px;
                background-position-x: 16px;
            }
        }

        .product-img-nav {
            position: relative;
            overflow: hidden;
            padding: 0 25px 15px;
            border-bottom: 1px solid #e0daf4;
            margin-bottom: 15px;

            .uk-slider-items > li {
                padding: 0 5px;
                width: 20%;
                cursor: pointer;
            }

            .slider-nav {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                display: block;
                text-indent: -9999px;
                width: 9px;
                height: 16px;

                &.prev {
                    left: 0;
                    background: url(/images/ico-17.png) no-repeat;
                }

                &.next {
                    right: 0;
                    background: url(/images/ico-18.png) no-repeat;
                }

            }
        }

        .tl {
            font: 17px/20px 'Roboto Regular', sans-serif;
            color: $black;
            border-bottom: 1px solid #178ccd;
            position: relative;
            padding-bottom: 10px;
            margin-bottom: 15px;
            padding-left: 15px;

            &:after {
                content: '';
                position: absolute;
                background: url(/images/ico-19.png) no-repeat;
                width: 19px;
                height: 7px;
                bottom: -1px;
                left: 44px;
            }
        }

        .descr {
            overflow: hidden;
            margin-bottom: 15px;


        }
    }

    .intro-holder {
        overflow: hidden;
        padding-bottom: 30px;
    }

    .custom_hidden {
        height: 18px;
        overflow: hidden;
    }

    .read_more_on_prod_button {
        margin-top: 10px;
        display: block;
        width: 100%;
        text-align: center;
        font-weight: 700;
        cursor: pointer;
        margin-bottom: 30px;
    }

    .product-reviews {
        .tl {
            padding-top: 15px;
            border-top: 1px solid #e0daf4;

        }

        .product-review {
            border: 1px solid #f9f9f9;
            margin-bottom: 15px;

            .product-review-header {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #f9f9f9;
                padding: 5px 10px;
            }

            .product-review-body {
                padding: 10px;

                p {
                    margin: 0 0 10px;
                }
            }
        }

        .review-pagination {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 10px;

            a {
                padding: 6px 12px;
                margin-left: -1px;
                line-height: 1.42857143;
                color: #818181 !important;
                text-decoration: underline !important;
                background: 0 0;
                font-size: 18px;
                border: 0;
            }
        }

        .write-review {
            .form-group {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 10px;
                }

                .label {
                    font-weight: 700;
                    padding: 5px 0;
                    color: #303030;
                    display: inline-block;

                }

                .input {
                    width: 100%;
                    height: 40px;
                    border-radius: 0;
                    background: 0 0;
                    border: 1px solid #e6e6e6;
                    padding: 6px 12px;
                }

                .textarea {
                    padding: 6px 12px;
                    width: 100%;
                    border-radius: 0;
                    background: 0 0;
                    border: 1px solid #e6e6e6;
                    resize: none;
                }

            }

            .rate-submit {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;

                .btn-05 {
                    @include black_btn;
                }
            }

            .user-rating {
                display: flex;

                align-items: center;

                div.label {
                    margin-right: 15px;
                    font-weight: 700;
                    padding: 5px 0;
                    color: #303030;
                    display: inline-block;
                }

            }
        }

        .enter-or-registr {
            padding: 15px 5px;
            border-top: 1px solid #e0daf4;
            color: #000;
            font: 18px/20px 'RobotoCondensedRegular', sans-serif;
            text-align: center;
            line-height: 20px;
            margin-bottom: 15px;
            background: #b2d6eb;

            a {
                text-decoration: underline !important;
                color: #f32450 !important;
            }
        }
    }
}

@media (max-width: 900px) {
    .product-page .product-wrapper {
        grid-template-columns: 1fr;
        grid-gap: 0;

        .product-left {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
        }

        .product-right {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
        }

        .product-reviews {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 3;
            grid-row-end: 4;
        }

        .product-h1 {
            line-height: 1;
        }

        .product-img-slider {
            .navigate {
                display: flex;
            }
        }

        .price-box {
            .price-block {
                margin-top: 6px;
                margin-bottom: 25px;
                padding-left: 0;
            }

            .buy-buttons {
                display: block;

                .buy-btn, .buy-one-click-btn {
                    float: none !important;
                    margin-bottom: 6px;
                    display: block;
                    text-align: center;
                }
            }
        }

        .product-img-nav {
            display: none;
        }
    }
}

@media (max-width: 1150px) {
    .product-page .product-wrapper .size-box .opt-info {
        float: left;
    }
}

@media (max-width: 525px) {
    .product-page .product-wrapper .size-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .info-box {
            margin-top: 20px;
            float: initial;
            display: flex;
            flex-direction: column;
            align-items: center;
            .inf{
                padding-left: 0;
                text-align: center;
            }
        }

        .opt-info {
            float: initial;
            background: none;
            padding-left: 0;
            text-align: center;
        }
    }
}
