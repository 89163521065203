// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$gray:#e4e4e4;
$black:black;
$black-two:#090909;
$red:#f32450;
$white: white;
$blue: #0181c8;
